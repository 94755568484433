.DesktopEmail {
	z-index: 1;
	position: absolute;
	width: 100%;
	pointer-events: none;
	.desktopEmailWrapper {
		position: relative;
		grid-column: 7 / -1;
		margin-left: 20px;
		visibility: hidden;
		span {
			overflow: hidden;
			display: block;
			span {
				display: block;
				transform: translateY(100%);
				transition: transform 0.6s cubic-bezier(.5,0,0,1),-webkit-transform 1s cubic-bezier(.5,0,0,1);
				h3 {
					color: white;
					font-family: Overpass Extra Bold;
					width: 341px;
					font-weight: 400; } } }
		label {
			color: white;
			font-family: Overpass;
			position: absolute;
			bottom: 0px;
			pointer-events: none;
			opacity: 0;
			// transition: opacity 0.4s 0.3s, transform 0.15s, color 0.15s
			transition: opacity 0.4s 0.3s, bottom 0.15s, color 0.15s;
			// transition-delay: opacity 0.5s
			h4 {
				transition: font-size 0.15s; } }
		.submitIcon {
			position: absolute;
			left: 315px;
			bottom: 10px;
			opacity: 0;
			transition: opacity 0.4s 0.3s;
			cursor: pointer;
			pointer-events: all;
 }			// transition-delay: opacity 0.5s
		input[type="email"] {
			margin: 3.5em 0em 0em 0em;
			padding-bottom: 4px;
			border-color: white;
			width: 300px;
			padding-right: 37px;
			color: white;
			opacity: 0;
			transition: opacity 0.4s 0.3s, border-color 0.15s; }
		input:-webkit-autofill {
			caret-color: white;
			-webkit-text-fill-color: white;
			-webkit-box-shadow: 0 0 0 30px rgb(35, 27, 27) inset !important; }
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 30px rgb(35, 27, 27) inset !important;
 }			// transition-delay: opacity 0.5s
		.dot-wrap {
			position: absolute;
			left: 313px;
			bottom: 11px;
			// opacity: 0
			.dot-flashing {
				margin: 0 auto;
				position: relative;
				width: 8px;
				height: 8px;
				border-radius: 5px;
				background-color: #d878e7;
				color: #d878e7;
				animation: dotFlashing 1s infinite linear alternate;
				animation-delay: .5s; }
			.dot-flashing::before, .dot-flashing::after {
				content: '';
				display: inline-block;
				position: absolute;
				top: 0; }
			.dot-flashing::before {
				left: -14px;
				width: 8px;
				height: 8px;
				border-radius: 5px;
				background-color: #d878e7;
				color: #d878e7;
				animation: dotFlashing 1s infinite alternate;
				animation-delay: 0s; }
			.dot-flashing::after {
				left: 14px;
				width: 8px;
				height: 8px;
				border-radius: 5px;
				background-color: #d878e7;
				color: #d878e7;
				animation: dotFlashing 1s infinite alternate;
				animation-delay: 1s; } }
		.Error {
			position: absolute;
			margin-top: 6px;
			color: #C247C3;
			font-family: Lato; }
		&.focused {
			label {
				// transform: translateY(-115%)
				bottom: 28px;
				color: #717171;
				h4 {
					font-size: 1em; } }
			input[type="email"] {
				border-color: #C247C3; } }
		&.success {
			input[type="email"] {
				border-color: #47C34A; } } }
	// .hold-icon
	// 	opacity: 0
	// 	visibility: hidden
	// 	transition: opacity 0.3s 0.4s
	// 	p
	// 		color: white
	&.active {
		pointer-events: none;
		.desktopEmailWrapper {
			visibility: visible;
			span {
				span {
					transform: translateY(0%); } }
			label {
				opacity: 1; }
			.submitIcon {
				opacity: 1; }
			input[type="email"] {
				pointer-events: all;
				opacity: 1; } }
		// .hold-icon
		// 	position: relative
		// 	width: 120px
		// 	visibility: visible
 }		// 	opacity: 1
	// &.focused
	// 	.desktopEmailWrapper
	// 		label
	// 			transform: translateY(-100%)
	// 			color: #717171
	// 			h4
	// 				font-size: 1em
	// 		input[type="email"]
 }	// 			border-color: #C247C3


@keyframes dotFlashing {
  0% {
    background-color: #d878e7; }
  50%,
  100% {
    background-color: #ebe6ff; } }

@media (min-width: 1280px) {
	.DesktopEmail {
		.desktopEmailWrapper {
			grid-column: 8 / -1;
			margin-left: 20px;
			span {
				span {
					h3 {} } }
			input[type="email"] {
 } } } }				// margin: 0
