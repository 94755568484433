.App {
	padding: 0;
	margin: 0;
	// height: 100vh
	// height: -webkit-fill-available
	height: 100%;
	width: 100%;
	.LandscapeError {
		width: 100%;
		height: 100%;
		position: absolute;
		background: rgb(249, 250, 252);
		z-index: 20;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		img {
			width: 50px;
			margin-bottom: 2em; }
		h3 {
			font-family: Lato; } } }
