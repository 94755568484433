.MobileEmailForm {
	height: 205px;
	width: 100%;
	position: absolute;
	z-index: 3;
	// background-color: #F4F4F4
	bottom: 0;
	display: flex;
	overflow-x: auto;
	// flex-direction: column
	align-items: center;
	scroll-snap-type: x mandatory;
	// border-width: 1px 0px 0px 0px
	// border-style: solid
	.test {
		position: fixed;
		top: 0; }
	&::-webkit-scrollbar {
		display: none; }
	.EmailScrollWrap {
		width: 100%;
		height: 100%;
		flex-shrink: 0;
		scroll-snap-align: start;
		display: flex;
		align-items: center;
		flex-direction: column;
		box-sizing: border-box;
		padding-top: 1.5rem;
		// justify-content: center
		h5 {
			font-family: Lato;
			font-weight: 600;
			// padding: 1.3em 0em 1.2em 0em
			letter-spacing: 1px;
 }			// font-size: em
		.Email {
			margin-top: 1.5rem; } }
	.SocialMedia {
		width: 100%;
		height: 100%;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		scroll-snap-align: start;
		.wrap {
			width: 270px;
			display: flex;
			padding-bottom: 0.5rem;
			justify-content: space-between;
			> * div {
				width: 50px;
				height: 50px;
				border-radius: 14px;
				display: flex;
				align-items: center;
				justify-content: center;
				box-shadow: 2px 2px 14px #aabbc933;
				img {
					width: 100%; }
				svg {
					width: 24px;
					height: 24px; } } } }
	&.darkMode {
		.SocialMedia {
			.wrap {
				> * div {
					border: none;
					box-shadow: 2px 3px 15px #00000033; } } } } }
.hover {
	z-index: 10;
	position: relative;
	.mode-switch {
		border: none;
		-webkit-appearance: none;
		position: absolute;
		bottom: 10px;
		right: 10px;
		// align-self: flex-end
		// margin: 10px 10px 0px 0px
		width: 30px;
		height: 30px;
		font-family: Overpass;
		background: transparent;
		padding: 0px;
		text-align: center;
		border-radius: 15px;
		z-index: 10;
		display: flex;
		justify-content: center;
		align-items: center;
		// background: #ebf2fa
		// box-shadow: -4px -2px 9px rgba(255, 255, 255, 0.9), 4px 2px 18px rgba(170, 187, 201, 0.8)
		svg {
			width: 18px;
			height: 18px;
			margin-bottom: 2px; }
		&:focus {
			outline: none; } }
	.Dots {
		position: absolute;
		bottom: 10px;
		left: 0;
		right: 0;
		// margin: 0 auto
		display: flex;
		// align-items: center
		justify-content: center;
		.wrap {
			position: relative;
			display: flex;
			width: 20px;
			justify-content: space-between;
			> * {
				width: 6.5px;
				height: 6.5px;
				border-radius: 3.5px;
				// background: black
 }				// position: absolute
			.indicator {
				position: absolute;
 }				// right: 0
			.page {} } } }

