.DesktopInstructions {
	transition: left 0.3s, top 0.3s;
	position: absolute;
	.hold-icon {
		display: flex;
		align-content: center;
		justify-content: center;
		width: 175px;
		img {
			width: 19.5px; }
		p {
			font-size: 0.9rem;
			margin-left: 0.6rem;
			color: white;
			font-family: Lato;
			line-height: 1.1rem; } } }


