.Email {
	input[type='email'] {
		font-family: Lato;
		font-size: 1.1em;
		border: none;
		margin: 0 auto;
		-webkit-appearance: none;
		background: transparent;
		display: block;
		width: 290px;
		outline: none;
		padding: 0px;
		padding-bottom: 3px;
		padding: 0px 3px 3px 0px;
		border-bottom: 2px solid black;
		border-radius: 0px;
		&::placeholder {
			color: #abacb4; }
		&:focus {
			border-bottom: 2px solid #334669; } }
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px rgb(249, 250, 252) inset !important; }
	&.darkmode {
		input:-webkit-autofill {
			caret-color: white;
			-webkit-text-fill-color: white;
			-webkit-box-shadow: 0 0 0 30px rgb(46, 49, 56) inset !important; }
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 30px rgb(46, 49, 56) inset !important; }
		input[type='email'] {
			&::placeholder {
				color: #7F8489; } } }
	.submitWrapper {
		position: relative;
		width: 300px;
		margin: 0 auto;
		input[type='submit'] {
			width: 100%;
			font-family: Lato;
			-webkit-appearance: none;
			margin-top: 1em;
			box-shadow: inset 0px 0px 2px 1px #FFFFFF;
			border: none;
			border-radius: 20px;
			letter-spacing: 0.5px;
			padding: 0.8em;
			font-size: 1em;
			color: white;
			display: block;
			&:focus {
				outline: none; } }
		.dot-wrap {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 17px;
			.dot-flashing {
				margin: 0 auto;
				position: relative;
				width: 10px;
				height: 10px;
				border-radius: 5px;
				background-color: #d878e7;
				color: #d878e7;
				animation: dotFlashing 1s infinite linear alternate;
				animation-delay: .5s; }
			.dot-flashing::before, .dot-flashing::after {
				content: '';
				display: inline-block;
				position: absolute;
				top: 0; }
			.dot-flashing::before {
				left: -15px;
				width: 10px;
				height: 10px;
				border-radius: 5px;
				background-color: #d878e7;
				color: #d878e7;
				animation: dotFlashing 1s infinite alternate;
				animation-delay: 0s; }
			.dot-flashing::after {
				left: 15px;
				width: 10px;
				height: 10px;
				border-radius: 5px;
				background-color: #d878e7;
				color: #d878e7;
				animation: dotFlashing 1s infinite alternate;
				animation-delay: 1s; } } }
	.error {
		font-family: Lato;
		font-size: 13px;
		letter-spacing: 0.5px;
		font-weight: 400;
		color: #d878e7;
		position: absolute;
		left: 0;
		right: 0;
		text-align: center;
		padding: 7px 0px 0px 0px; } }


@keyframes dotFlashing {
  0% {
    background-color: #d878e7; }
  50%,
  100% {
    background-color: #ebe6ff; } }


// .Email
// 	input[type='email']
// 		font-family: Overpass
// 		font-size: 1.1em
// 		border: none
// 		margin: 0 auto
// 		-webkit-appearance: none
// 		background: transparent
// 		display: block
// 		width: 300px
// 		outline: none
// 		padding: 0px
// 		border-bottom: 2px solid black
// 		border-radius: 0px
// 		&::placeholder
// 			transition: color 150
// 			color: black
// 		&.darkmode
// 			&::placeholder
// 				color: white
// 	input[type='submit']
// 		width: 100%
// 		font-family: Lato
// 		-webkit-appearance: none
// 		margin-top: 1.2em
// 		box-shadow: inset 0px 0px 2px 1px #FFFFFF
// 		border: none
// 		border-radius: 20px
// 		letter-spacing: 0.8px
// 		padding: 0.8em
// 		font-size: 1em
// 		color: white
// 		display: block
// 		&:focus
// 			outline: none
// 	input:-webkit-autofill
// 		// -webkit-text-fill-color: transparent
// 		// -webkit-box-shadow: 0 0 0px 1000px #ffffff00 inset
// 		// background: transparent
// 		// background-image: linear-gradient(to right, #D631D3, #AC16A7)
// 		// background: #EE84FF
// 	input:-webkit-autofill,
// 	input:-webkit-autofill:hover,
// 	input:-webkit-autofill:focus,
// 	input:-webkit-autofill:active
// 		-webkit-box-shadow: 0 0 0 30px rgb(249, 250, 252) inset !important
// 	&.darkmode
// 		input:-webkit-autofill
// 			-webkit-text-fill-color: white
// 			-webkit-box-shadow: 0 0 0 30px rgb(46, 49, 56) inset !important
// 		input:-webkit-autofill:hover,
// 		input:-webkit-autofill:focus,
// 		input:-webkit-autofill:active
// 			-webkit-box-shadow: 0 0 0 30px rgb(46, 49, 56) inset !important
// 	.error
// 		font-family: Lato
// 		font-size: 13px
// 		letter-spacing: 0.5px
// 		font-weight: 400
// 		color: #d878e7
// 		position: absolute
// 		left: 0
// 		right: 0
// 		text-align: center
// 		padding: 7px 0px 0px 0px
// 	.dot-wrap
// 		position: absolute
// 		left: 0
// 		right: 0
// 		bottom: 3.75em
// 		.dot-flashing
// 			margin: 0 auto
// 			position: relative
// 			width: 10px
// 			height: 10px
// 			border-radius: 5px
// 			background-color: #d878e7
// 			color: #d878e7
// 			animation: dotFlashing 1s infinite linear alternate
// 			animation-delay: .5s

// 		.dot-flashing::before, .dot-flashing::after
// 			content: ''
// 			display: inline-block
// 			position: absolute
// 			top: 0


// 		.dot-flashing::before
// 			left: -15px
// 			width: 10px
// 			height: 10px
// 			border-radius: 5px
// 			background-color: #d878e7
// 			color: #d878e7
// 			animation: dotFlashing 1s infinite alternate
// 			animation-delay: 0s


// 		.dot-flashing::after
// 			left: 15px
// 			width: 10px
// 			height: 10px
// 			border-radius: 5px
// 			background-color: #d878e7
// 			color: #d878e7
// 			animation: dotFlashing 1s infinite alternate
// 			animation-delay: 1s


// @keyframes dotFlashing
//   0%
//     background-color: #d878e7
//   50%,
//   100%
//     background-color: #ebe6ff

