@font-face {
	font-family: Lato;
	src: url(../../../src/assets/fonts/Lato-Regular.ttf);
	font-weight: 400; }

@font-face {
	font-family: Lato;
	src: url(../../../src/assets/fonts/Lato-Bold.ttf);
	font-weight: 700; }

@font-face {
	font-family: Lato;
	src: url(../../../src/assets/fonts/Lato-Black.ttf);
	font-weight: 900; }

@font-face {
	font-family: Lato;
	src: url(../../../src/assets/fonts/Lato-Light.ttf);
	font-weight: 300; }

@font-face {
	font-family: Lato;
	src: url(../../../src/assets/fonts/Lato-Thin.ttf);
	font-weight: 100; }
