@import '/layout/grid';
@import '/util/tools';

@import '/fonts/aileron';
@import '/fonts/overpass';
@import '/fonts/lato';

@import '/Components/App';
@import '/Components/MobileAnimation';
@import '/Components/GradientShow';
@import '/Components/MobileEmailForm';
@import '/Components/Email';
@import '/Components/DesktopAnimation';
@import '/Components/Text';
@import '/Components/Orb';
@import '/Components/DesktopEmail';
@import '/Components/DesktopInstructions';
@import '/Components/ColorShow';

body {
	// overflow: hidden
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; }

body, #root, html, canvas, {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%; }

body {
	font-size: 16px; }

p, h5, h4, h3, h2, h1 {
	margin: 0; }

p {
	font-size: 1rem; }

h5 {
	font-size: 1.2rem; }

h4 {
	font-size: 1.44rem; }

h3 {
	font-size: 1.728rem; }

h2 {
	font-size: 2.074rem; }

h1 {
	font-size: 2.488rem; }

@media screen and ( min-width: 900px ) {
	body {
		overflow: hidden; } }

